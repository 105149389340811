<template>
  <div class="base-icon" :style="{ width, height }">
    <img :src="require(`../assets/img/icon/${name}.svg`)" />
  </div>
</template>

<script>
export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      require: true,
    },
    width: {
      type: String,
      default: "98px",
    },
    height: {
      type: String,
      default: "98px",
    },
  },
};
</script>

<style lang="scss">
.base-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c3131b;
  border-radius: 50%;
  img {
    width: 45%;
  }
}
</style>