<template>
  <div>
    <h3 class="text-center mt-5">線下掃描活動</h3>
    <div class="text-center mt-5">請先加入好友才能領取抽獎機會喔~</div>
    <div class="row">
      <div class="col-md-12 text-center mt-5">
        <BaseBtn text="加入好友" @click="toFriend()" v-if="isFriend == false" />
        <BaseBtn text="關閉" @click="toClose()" v-if="isFriend == true" />
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import liff from "@line/liff";
import PageTitle from "@/components/PageTitle.vue";
import BaseBtn from "@/components/BaseBtn.vue";
import BaseIcon from "@/components/BaseIcon.vue";

export default {
  name: "ScanEvent",
  components: {
    PageTitle,
    BaseBtn,
    BaseIcon,
  },
  data() {
    return {
      eventTag: null,
      isFriend: false
    };
  },
  computed: {
  },
  methods: {
    async lineInit() {
      await liff
        .init({
          liffId: process.env.VUE_APP_LIFFID,
        })
        .then(async () => {
          //detect login or not
          if (!liff.isLoggedIn()) {
            liff.login({ redirectUri: window.location.href, bot_prompt: 'aggressive' })
          }

          await liff.getProfile()
            .then((res) => {
              window.localStorage.setItem("userToken", res.userId)
              window.localStorage.setItem("userName", res.displayName)
            })
            .catch((e) => {
              console.log(e)
            });

          if (await this.getFriend()) {
            await this.joinEvent()
          } else {
            await this.waitFriend()
          }
        })
        .catch((err) => {
          console.log(err.code, err.message);
        })
    },
    /** 是否好友 */
    async getFriend() {
      let r = _.get(await liff.getFriendship(), 'friendFlag', false)
      console.log('是否為好友')
      this.isFriend = r
      return r
    },
    toFriend() {
      window.open(process.env.VUE_APP_ADDFRIEND);
    },
    toClose() {
      window.location = process.env.VUE_APP_ADDFRIEND;
    },
    async waitFriend() {
      while (true) {
        console.log('waiting...')
        await this.sleep(500)
        if (await this.getFriend()) {
          break
        }
      }
      await this.joinEvent()
    },
    async sleep(t) {
      await new Promise(resolve => { setTimeout(resolve, t) })
    },
    async joinEvent() {
      /** todo: 打後端參加活動api */
      console.log('領取獎勵')
      let url = process.env.VUE_APP_APIUrl + "api/v1/scan/" + this.eventTag
      let data = {
        lineToken: window.localStorage.getItem("userToken"),
      }
      this.axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.toClose();
        })
        .catch((e) => {
          console.log(e);
          alert("領取失敗 請洽管理員");
        });
    },
    async sendMessage(msg) {
      /** todo: 可能串接line message api*/
      // https://api.line.me/oauth2/v2.1/verify?id_token=${liff_id_token}&client_id=${liff_channel_id}
      // https://developers.line.biz/en/reference/line-login/#verify-id-token
      // https://developers.line.biz/en/reference/messaging-api/#send-push-message
    },
    routeTo(route) {
      this.$router.push(route)
    },
  },
  created: function () {
    if (process.env.VUE_APP_GAME_GUID === undefined) {
      this.eventTag = '18529501f88gdm'
    } else {
      this.eventTag = process.env.VUE_APP_GAME_GUID
    }
    console.log(this.eventTag)

    this.lineInit()
  }
};
</script>